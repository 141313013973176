import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/ps-customer-server/list',
                name: 'ps-customer-server.list',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list'
                    }]
                },
                component: () => import('@/views/ps-customer-server/List.vue')
            },
            {
                path: '/ps-customer-server/add',
                name: 'ps-customer-server-add',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list'
                    },
                    {
                        title: 'Add',
                        name: 'ps-customer-server-add'
                    }]
                },
                component: () => import('@/views/ps-customer-server/Add.vue')
            },
            {
                path: '/site-assessment/list',
                name: 'site-assessment.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Site assessment',
                            name: 'site-assessment.list'
                        },
                    ]
                },
                component: () => import('@/views/site-assessment/List.vue')
            },
            {
                path: '/site-assessment/add',
                name: 'site-assessment.add',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Site assessment',
                            name: 'site-assessment.list'
                        },
                        {
                            title: 'Add',
                            name: 'site-assessment.add'
                        }
                    ]
                },
                component: () => import('@/views/site-assessment/Add.vue')
            },
            {
                path: '/transformer/list',
                name: 'transformer.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Transformer',
                            name: 'site-assessment.transformer'
                        },
                    ]
                },
                component: () => import('@/views/transformer/List.vue')
            },
            {
                path: '/ddi-library/list',
                name: 'ddi-library.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'DDI Library',
                            name: 'ddi-library.list'
                        },
                    ]
                },
                component: () => import('@/views/ddi-library/List.vue')
            },
            {
                path: '/master-variable/list',
                name: 'master-variable.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Master Variable',
                            name: 'master-variable.list'
                        },
                    ]
                },
                component: () => import('@/views/master-variable/List.vue')
            },
            {
                path: '/data-sync/list',
                name: 'data-sync.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Data Sync',
                            name: 'data-sync.list'
                        },
                    ]
                },
                component: () => import('@/views/data-sync/List.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/auth/Login.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/pages/404.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    },
    {
        path: '/saml/acs-verify-token',
        name: "token",
        component: () => import('@/views/pages/auth/Callback.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

export default router;
