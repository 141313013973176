import { acceptHMRUpdate, defineStore } from "pinia";
import { ref, computed } from "vue";
import { useLocalStorage } from "@vueuse/core";
import { type Token, type UserData, type UserCredentials } from "@/models/users";
import { type sfTokenResponse } from "@/models/psCustomerServer";

// {"email":"selsokka@capsuletech.com","st_fullname":"Sameh Elsokkary","st_username":"selsokka","userType":1}
// type User = {
//     email: string,
//     fullName: string,
//     userName: string,
//     userType: string
// }
// export type UserData = User

export interface PsEstimationType {
  token: string | undefined;
  active: boolean;
  exp: number;
  iat: number;
}

export const useUserSession = defineStore("userSession", () => {
  // token will be synced with local storage
  // @see https://vueuse.org/core/usestorage/
  let token = useLocalStorage("token", "");
  // let psSfToken = useLocalStorage('psSfToken', '')

  const psSfToken = useLocalStorage("sfdetails", {
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlVzZXJuYW1lIjoic2Vsc29ra2EiLCJleHAiOjE3MzQwNjUxNTQsInNlc3Npb25JZCI6IlZ3R2hna2lxN3dNYkFjV2hQZWM4YWF5VXliVllEcURNVUZ2cWZVdnQxSm1zK25LanBvbE5yUHpPVnZjekFCNHREaWxLdnQ4QjlDcldKeW9FM1VHWHBrcEZVMUlUTm9uUVhpcDdyS3o5UHc4YzZnc1NBU01IQVlFZWJIYVl2ZVM4ZzJQNDN3VXhnamVZY2wwZ2h4dzZ0bFl2UnA2dzVFWTZaV1NORVg0YVdLcldzNVhHN3VSV0tkdUhuTGpDTlZsUXhzVUZqaDg0TVNrZU9CeTY2RVlFMzloSlVRPT0ifQ.qLlMdFCPKlAHPrYwI5PhveFlIE9SIa6U9j-bEtF6Irg",
    active: true,
    exp: 0,
    iat: 0,
  });

  const user = ref<UserData>(
    useLocalStorage("vueuseuser", {
      email: "",
      fullName: "",
      userName: "",
      userType: "",
    }) as any
  );

  const loading = ref(true);

  const isLoggedIn = computed(() => token.value != "");

  const getPsCustomerServerTokenDetails = computed(() => psSfToken.value);

  const isSalesForceLoggedIn = computed(
    () => psSfToken.value.token != "" && psSfToken.value.active
  );

  function setUser(newUser: UserData) {
    user.value = newUser;
  }

  function setToken(newToken: string) {
    token.value = newToken;
  }

  function setPsCustomerAuthDetail(tokenDetails: sfTokenResponse) {
    psSfToken.value.token = tokenDetails.access_token;
    psSfToken.value.active = tokenDetails.active;
    psSfToken.value.exp = tokenDetails.exp;
    psSfToken.value.iat = tokenDetails.iat;
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading;
  }

  async function logoutUser() {
    psSfToken.value = {
      token: "",
      active: false,
      exp: 0,
      iat: 0,
    };
    token.value = "";
    user.value = {
      email: "",
      fullName: "",
      userName: "",
      userType: "",
    };
  }

  async function login(data: UserData & Token) {
    const userDetail = {
      email: data.email,
      fullName: data.fullName,
      userName: data.userName,
      userType: data.userType,
    };
    // store user details and jwt in local storage to keep user logged in between page refreshes
    setToken(data.token);
    token.value = data.token;
    setUser(userDetail);
    // redirect to previous url or default to home page
    // router.push(this.returnUrl || '/');
  }

  return {
    user,
    psSfToken,
    token,
    isLoggedIn,
    loading,
    isSalesForceLoggedIn,
    getPsCustomerServerTokenDetails,
    logoutUser,
    setUser,
    setToken,
    setLoading,
    login,
    setPsCustomerAuthDetail,
  } as const;
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot));
}
