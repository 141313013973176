<script setup>
import { useLayout } from '@/layout/composables/layout';

const { layoutConfig } = useLayout();
</script>

<template>
    <!--<div class="layout-footer mt-auto">
        <div class="footer-start">
             <img :src="'/layout/images/logo-' + (layoutConfig.colorScheme.value === 'light' ? 'dark' : 'light') + '.png'" alt="logo" />
            <span class="app-name">CapMagic</span> 
        </div>
        <div class="footer-right">
             <span>© CapMagic</span> 
        </div>
    </div> -->
</template>
