<script setup>
import { ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        label: 'Ps Customer Server',
        icon: 'pi pi-home',
        to: '/ps-customer-server/list',
    },
    {
        label: 'Site assessment',
        icon: 'pi pi-desktop',
        to: '/site-assessment/list',
    },
    {
        label: 'Transformer',
        icon: 'pi pi-cog',
        to: '/transformer/list',
    },
    // {
    //     label: 'DDI Library',
    //     icon: 'pi pi-id-card',
    //     to: '/ddi-library/list',
    // },
    // {
    //     label: 'Master Variable',
    //     icon: 'pi pi-address-book',
    //     to: '/master-variable/list',
    // },
    // {address-bookaddress-book
    //     label: 'Data Sync',
    //     icon: 'pi pi-sync',
    //     to: '/data-sync/list',
    // }
    {
        label: 'Administration',
        icon: 'pi pi-cog',
        items: [
            {
                label: 'DDI Library',
                icon: 'pi pi-id-card',
                to: '/ddi-library/list',
            },
            {
                label: 'Master Variable',
                icon: 'pi pi-address-book',
                to: '/master-variable/list',
            },
            {
                label: 'Data Sync',
                icon: 'pi pi-sync',
                to: '/data-sync/list',
            }
        ]
    },
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
